type TagList = {
  [tag: string]: string;
};

function capitalize(str: string) {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

function fillTags(target: string, tags: TagList): string {
  if (target == null || target == undefined) return '';

  Object.keys(tags).forEach((tag: string) => {
    let tagValue: string = tags[tag];
    target = target.split(tag).join(tagValue);
  });

  return target;
}

export default {
  fillTags: fillTags,
  capitalize: capitalize,
};
