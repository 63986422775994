import HoverableCard from '@/components/Cards/HoverableCard';
import Layout from '@/components/Layout/Layout';
import SEO from '@/components/SEO/Seo';
import SearchWidgetContainer from '@/components/SearchWidgetContainer';
import GeneralHeroSec from '@motokiki/shared/generalHeroSec';
import { graphql } from 'gatsby';
import React from 'react';
import parse from 'react-html-parser';

import Utils from './sharedUtils';
import './style.scss';

interface Props {
  data: {
    strapi: {
      carManufacturer: {
        name: string;
        logoLink: string;
        ManufacturerInfo: string;
        HeroSection: {
          activeLozenge: string;
          className: string;
          displayIcon: string;
          headerPrefix: string;
          headerText: string;
          iconImg: {
            url: string;
            alternativeText: string;
          };
          lozengeText: string;
          mainImg: {
            url: string;
            alternativeText: string;
          };
        };
        Seo: {
          description: string;
          title: string;
        };
        models: Array<{
          name: string;
          imageUrl: string;
          popular: Boolean;
        }>;
      };
      carBrandStatic: {
        title: string;
        useStaticTitle: boolean;
        topParagraph: {
          textBlock: string;
        };
        bottomParagraph: {
          textBlock: string;
        };
      };
    };
  };
}

const individualCarBrandsPage = ({ data }: Props) => {
  var {
    logoLink,
    name,
    ManufacturerInfo,
    HeroSection,
    Seo,
    models,
  } = data.strapi.carManufacturer;

  var {
    title,
    useStaticTitle,
    topParagraph,
    bottomParagraph,
  } = data.strapi.carBrandStatic;

  var link = (url: string) => {
    return url.toLowerCase().replace(' ', '_');
  };

  // Replace tags with car data
  const tags = {
    '[car-brand]': Utils.capitalize(name),
  };

  // replace tags in herosection
  if (HeroSection) {
    if (HeroSection.headerText)
      HeroSection.headerText = Utils.fillTags(HeroSection.headerText, tags);

    if (HeroSection.lozengeText)
      HeroSection.lozengeText = Utils.fillTags(HeroSection.lozengeText, tags);
  }

  if (useStaticTitle) title = Utils.fillTags(title, tags);
  const paragraphTop = Utils.fillTags(topParagraph.textBlock, tags);
  const paragraphBottom = Utils.fillTags(bottomParagraph.textBlock, tags);

  let popularModels = [];
  let otherModels = [];

  models.forEach((model) => {
    if (model.popular) popularModels.push(model);
    else otherModels.push(model);
  });

  return (
    <>
      {Seo ? <SEO title={Seo.title} description={Seo.description} /> : null}
      <Layout>
        {HeroSection ? (
          <GeneralHeroSec data={HeroSection} />
        ) : (
          <div style={{ height: '140px' }} />
        )}
        <div className="blockImageInfo">
          <img className="image" src={logoLink} alt="manufacturer brand logo" />
          <h2 className="info">
            {useStaticTitle ? title : parse(ManufacturerInfo)}
          </h2>
        </div>
        <div className="infoBlock">
          <div className="center">{parse(paragraphTop)}</div>
        </div>
        {popularModels.length > 0 ? (
          <h1 className="centeredTitle">Popular models</h1>
        ) : null}
        <div className="cardContainer">
          {popularModels.map((carModel) => {
            return (
              <HoverableCard
                key={carModel.name}
                data={{
                  title: carModel.name,
                  image: carModel.imageUrl,
                  redirectTo: link(`/tyres/cars/${name}/${carModel.name}`),
                  cardSize: 'small',
                  showArrow: true,
                }}
              />
            );
          })}
        </div>
        {otherModels.length > 0 ? (
          <h1 className="centeredTitle">Other</h1>
        ) : null}
        <div className="cardContainer">
          {otherModels.map((carModel) => {
            return (
              <HoverableCard
                key={carModel.name}
                data={{
                  title: carModel.name,
                  image: null,
                  redirectTo: link(`/tyres/cars/${name}/${carModel.name}`),
                  cardSize: 'small',
                  showArrow: true,
                }}
              />
            );
          })}
        </div>
        <SearchWidgetContainer />
        <div className="infoBlock">
          <div className="center">{parse(paragraphBottom)}</div>
        </div>
      </Layout>
    </>
  );
};

export const carBrandPages = graphql`
  query($id: ID!) {
    strapi {
      carManufacturer(id: $id, publicationState: PREVIEW) {
        HeroSection {
          activeLozenge
          className
          displayIcon
          headerPrefix
          headerText
          iconImg {
            url
            alternativeText
          }
          lozengeText
          mainImg {
            url
            alternativeText
          }
        }
        ManufacturerInfo
        logoLink
        name
        Seo {
          description
          title
        }
        models {
          name
          imageUrl
          popular
        }
      }
      carBrandStatic {
        title
        useStaticTitle
        topParagraph {
          textBlock
        }
        bottomParagraph {
          textBlock
        }
      }
    }
  }
`;

export default individualCarBrandsPage;
