import React, { ReactElement } from 'react';

import './HoverableCard.scss';

interface Props {
  data: {
    image: string;
    title: string;
    redirectTo: string;
    cardSize: 'small' | 'medium' | 'large';
    showArrow: boolean;
  };
}

const HoverableCard = (props: Props): ReactElement => {
  const { image, title, redirectTo, cardSize, showArrow } = props.data;

  const nullOrUndefined = (obj: any) => {
    return obj == null || obj == undefined ? true : false;
  };

  return (
    <a className={`card ${image ? image : 'noImage'}`} href={redirectTo}>
      {image ? (
        <div className="imageHolder">
          <img className="image" src={image} />
        </div>
      ) : null}
      <div className={`title ${nullOrUndefined(image) ? 'single' : ''}`}>
        {title}
        {showArrow ? <div className="arrow" /> : null}
      </div>
    </a>
  );
};

export default HoverableCard;
